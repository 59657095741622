<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div
    id="checkout"
    class="m-5"
  >
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col
            md="6"
            sm="6"
            lg="6"
          >
            <!-- title text and switch button -->
            <div class="text-center">
              <h3 class="mt-2 font-weight-bolder">
                Payment
              </h3>
              <p class="mt-2 pb-75 font-weight-bolder">
                {{ getCreateGroupData.name ? getCreateGroupData.name : 'package-name' }}
              </p>
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bolder"
                    style="color:black;"
                  >$</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder"
                    style="color:black;"
                  >{{ packagePrice(getCreateGroupData) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
              </div>
            </div>
            <div
              v-if="getUserExistingCard.length"
              class="ml-1 mb-2"
            >
              <div class="font-weight-bolder pb-1">
                Please select if you want to use the existing card below.
              </div>
              <b-form-checkbox
                id="checkbox-1"
                v-model="useExistingCard"
                name="checkbox-1"
              >
                XXXX-XXXX-XXXX-{{ getUserExistingCard[0].card_object.last4 }}
              </b-form-checkbox>
            </div>
            <div v-show="!useExistingCard">
              <b-form @submit.prevent>

                <b-col>
                  <b-form-group>
                    <label
                      class="font-weight-bold"
                    >Card number</label>
                    <b-input-group class="input-group-merge">
                      <div
                        id="card-number"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-row class="ml-0 mr-0">
                  <b-col md="6">
                    <b-form-group>
                      <label
                        for="card-expiry"
                        class="font-weight-bold"
                      >Validity</label>
                      <b-input-group class="input-group-merge">
                        <div id="card-expiry" />
                        <div class="card-icon">
                          <img
                            src="@/assets/images/Expiry.png"
                            class="cursor-pointer"
                            alt="expiry-icon"
                          >
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label
                        for="card-cvc"
                        class="font-weight-bold"
                      >CVC</label>
                      <b-input-group class="input-group-merge">
                        <div id="card-cvc" />
                        <div class="card-icon">
                          <img
                            src="@/assets/images/CVC.png"
                            class="cursor-pointer"
                            alt="cvc-icon"
                          >
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <div
                      id="card-error"
                      class="mt-1 mb-2"
                    />
                  </b-col>
                </b-row>
              </b-form>

              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  style="width: inherit !important;"
                  :disabled="lockSubmit"
                  @click="createToken"
                >
                  <div
                    v-if="lockSubmit"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  {{ getCreateGroupData.trial_month_count === 0 ? "Pay" : "Add Card" }}
                </b-button>
              </b-col>
            </div>
            <div v-show="useExistingCard">
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  style="width: inherit !important;"
                  :disabled="lockSubmit"
                  @click="createNewGroup"
                >
                  <div
                    v-if="lockSubmit"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  {{ getCreateGroupData.trial_month_count === 0 ? "Pay" : "Add Card" }}
                </b-button>
              </b-col>
            </div>
          </b-col>
          <b-col
            md="4"
            sm="4"
            lg="4"
            offset-sm="2"
            offset-md="2"
            offset-lg="2"
          >
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                src="@/assets/images/simiicons/device.svg"
                class="mb-1 mt-1"
                alt="basic svg img"
              />
              <!--/ img -->
              <h4 class="mt-1">
                {{ getCreateGroupData.name ? getCreateGroupData.name : 'package-name' }}
              </h4>
              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ packagePrice(getCreateGroupData) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
              </div>
              <span>
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in getCreateGroupData.id === 4 ? featuresListPowerSaving : featuresList"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
              </span>
              <!-- <span
                v-for="(feature, fIndex) in getCreateGroupData.plan_has_feature"
                :key="fIndex"
              >
                <b-form-checkbox
                  v-if="!feature.feature.isDisabled"
                  v-model="feature.feature.isSelected"
                  size="sm"
                  plain
                  :name="feature.feature.name"
                  :value="true"
                  :disabled="feature.feature.isDisabled"
                  @change="handleFeatureList()"
                >{{ feature.feature.name }}</b-form-checkbox>
              </span> -->

              <!--/ plan benefit -->
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <!--/ pricing plan cards -->
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BFormGroup,
  BInputGroup,
  BButton,
  BForm,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { getFormDataFromJSON, showToast, packagePrice } from '@/common/global/functions'
import store from '@/store'
import ApplicationType from '@/common/enums/applicationTypeEnum'
import MemberQuota from '@/common/enums/memberQuotaEnum'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BFormGroup,
    BInputGroup,
    BButton,
    BForm,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    const groupData = await store.getters['user/getCreateGroupData']
    if (groupData.id == null) {
      console.log('in guard', groupData)
      next({
        name: 'pricing-plans',
      })
    } else { next() }
  },
  data() {
    return {
      hideForm: false,
      useExistingCard: false,
      token: null,
      packagePrice,
      cardHolder: null,
      cardNumber: null,
      cardExpiry: null,
      MemberQuota,
      cardCvc: null,
      lockSubmit: false,
      spinner: false,
      featuresList: [
        'Campaign Scheduler',
        'Monetization',
        'Media Storage',
        'Content Creator',
      ],
      featuresListPowerSaving: [
        'Accounts',
        'Invoices',
        'Balances',
        'Usage',
      ],
      name: '',
      selectPlan: '',
      planOptions: [],
      // validation
      required,
    }
  },
  computed: {
    getCreateGroupData() {
      return this.$store.getters['user/getCreateGroupData']
    },
    getUserExistingCard() {
      const user = this.$store.getters['user/getUser']
      return user?.stripe_customer_card ? user.stripe_customer_card : null
    },
    stripeElements() {
      return this.$stripe.elements()
    },
  },
  mounted() {
    const style = {
      base: {
        // color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }
    this.cardNumber = this.stripeElements.create('cardNumber', { showIcon: true, iconStyle: 'default', style })
    this.cardNumber.mount('#card-number')
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
    this.cardExpiry.mount('#card-expiry')
    this.cardCvc = this.stripeElements.create('cardCvc', { style })
    this.cardCvc.mount('#card-cvc')
  },
  beforeDestroy() {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  methods: {
    handleFeatureList() {
      packagePrice(this.getCreateGroupData)
    },
    hasLauncherFeature(features) {
      let found = false
      found = features.some(el => el.feature.application_type === ApplicationType.SIMI_LAUNCHER)
      return found
    },
    async createToken() {
      this.lockSubmit = true
      const { token, error } = await this.$stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message
        this.$forceUpdate() // Forcing the DOM to update so the Stripe Element can update.
        this.lockSubmit = false
        return
      }
      this.processTransaction(token)
    },
    processTransaction(transactionToken) {
      this.getCreateGroupData.source = transactionToken.id
      this.getCreateGroupData.card_id = transactionToken.card.id
      this.createNewGroup()
    },
    async createNewGroup() {
      this.lockSubmit = true
      this.spinner = true
      let groupObject = {}
      this.getCreateGroupData.payment_with_existing_card = this.useExistingCard
      const featureIds = this.getCreateGroupData.plan_has_feature
        .filter(plan => plan?.feature?.isSelected)
        .map(plan => plan.feature.id)
      groupObject = {
        payment_with_existing_card: this?.useExistingCard,
        source: this?.getCreateGroupData?.source,
        card_id: this?.getCreateGroupData?.card_id,
        name: this.getCreateGroupData?.groupName,
        plans: this.getCreateGroupData?.slug,
        quota: MemberQuota,
        image: this.getCreateGroupData?.image,
        planId: this.getCreateGroupData?.id,
        device_quota: this.getCreateGroupData?.screenQuota,
        selected_features: featureIds,
        trial_month_count: this.getCreateGroupData.trial_month_count ?? 0,
      }
      try {
        const formData = getFormDataFromJSON(groupObject)
        await this.$axios.post('user-has-group/create-user-group', formData)
        showToast('Checkout', 'Transaction succeeded')
        document.getElementById('card-error').innerHTML = ''
        this.cardNumber.clear()
        this.cardExpiry.clear()
        this.cardCvc.clear()
        this.lockSubmit = false
        await this.$store.dispatch('user/getUserIfNotExist', true)
        await this.$store.dispatch('user/setAllUserGroupIfNotPresent', true)
        await this.$router.push('/groups-listing')
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        showToast('Checkout', message.toString(), 'danger')
        document.getElementById('card-error').innerHTML = message.toString()
        this.cardNumber.clear()
        this.cardExpiry.clear()
        this.cardCvc.clear()
        this.lockSubmit = false
      }

      this.spinner = false
      this.lockSubmit = false
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
#checkout{
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
  .btns-class{
    width: 100%;
    margin: -15px;
    margin-bottom: 15px;
  }
  #card-number,
  #card-expiry,
  #card-cvc,
  #subscription{
    background: white;
    padding: 12px;
    border: 1px solid #d0d2d6;
    border-radius: 7px;
    width: 100%;
    position: relative;
  }
  .card-icon{
    position: absolute;
    right: 3%;
    top: 25%;
  }
  #custom-button{
    margin: 10px 0;
    display: block;
  }
  #card-error {
    color: red;
  }
}
</style>
